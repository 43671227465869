import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    getItem(key: string) {
        return localStorage.getItem(key);
    }

    getItemParsed(key: string): any {
        return JSON.parse(localStorage.getItem(key) as string)
    }

    setItem(key: string, value: any) {
        localStorage.setItem(key, value);
    }

    setItemStringified(key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    removeItem(key: string) {
        localStorage.removeItem(key);
    }

    clear() {
        localStorage.clear();
    }

}
